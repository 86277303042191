<template>
  <v-container>
    <v-main row wrap>
      <v-layout row wrap>
        <v-flex d-flex xs12 md12 sm12 class="mb-3 mx-0">
          <v-card flat>
            <h1 justify="center"><span style="color:red;">АНТИ</span>потеряшка</h1>
            <br><br><br>
            <v-layout>
              <br><br>
              <v-row>
                <v-col>
                  <v-card outlined>
                    <v-card-text primary-title>
                      <a href="docs/activate.pdf" target="__blank">Инструкция по активации</a>
                      <v-icon>picture_as_pdf</v-icon>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card outlined>
                    <v-card-text primary-title>
                      <a href="docs/edit.pdf" target="__blank">Инструкция по редактированию</a>
                      <v-icon>picture_as_pdf</v-icon>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col>
                  <v-card outlined>
                    <v-card-text primary-title>
                      <a href="docs/check.pdf" target="__blank">Инструкция по проверке</a>
                      <v-icon>picture_as_pdf</v-icon>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-layout>
            <br><br>
            <v-row no-gutters>
              <v-col>
                <v-card flat>
                  <v-card-text justify-left>
                    <span>У меня есть АНТИпотеряшка и я хочу её активировать или редактировать.</span><br>
                    <span>Нажав кнопку, Вы перейдете на страницу активации и редактирования Вашей контактной информации привязанной к номеру АНТИпотеряшки</span><br>
                    <span>
                      Для Активации или Редактирования необходим номер АНТИпотеряшки и Ваш реальный адрес электронной почты.
                      На адрес электронной почты придет одноразовая ссылка для входа.
                    </span><br>
                    <span>Для редактирования информации нужен номер АНТИпотеряшки и адрес электронной почты.</span>
                  </v-card-text>
                </v-card>
                <a href="https://ed.key4help.com/" target="__blank">
                  <v-btn x-large justify="start" outlined color="#42A5F5"><strong>Активировать</strong></v-btn>
                </a>
              </v-col>
              <v-col>
                <br><br>
                <v-card flat>
                  <v-card-text>
                    <span>Я нашел АНТИпотеряшку и хочу сообщить владельцу</span><br>
                    <span>Нажав кнопку, Вы перейдете на страницу с информацией, которую оставил владелец АНТИпотеряшки</span><br><br><br>
                  </v-card-text>
                </v-card>
                <a href="https://go.key4help.com/" target="__blank">
                  <v-btn x-large justify="end" outlined color="#EF5350"><strong>Я нашел антипотеряшку</strong></v-btn>
                </a>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-flex d-flex xs12 md12 sm12>
          <v-card flat class="mb-3">
            <v-row no-gutters>
              <v-col align-self="start">
                <br><br>
                <h2 justify="start">QR-код на <span style="color:red;">АНТИ</span>потеряшке покажет контакты владельца</h2><br>
                <v-img src="img/1.jpg" justify="start" max-height="700px" contain></v-img>
                <br><br>
              </v-col>
              <v-col align-self="end">
                <br><br>
                <h2 justify="end">Кому нужна <span style="color:red;">АНТИ</span>потеряшка?</h2><br><br>
                <v-row>
                  <v-col
                  v-for="n in cases"
                  :key="n.title"
                  class="d-flex child-flex"
                  cols="4"
                  >
                    <v-card flat outlined>
                      <v-flex xs12 md6 sm6>
                        <v-img
                          :src="`img/case${n.id}.jpg`"
                          :alt="`${n.title}`"
                          aspect-ratio="1"
                          class="grey lighten-2"
                        >
                        </v-img>
                      </v-flex>
                      <v-card-title>
                        {{ n.title }}
                      </v-card-title>
                      <v-card-text>
                        {{ n.text }}
                      </v-card-text>
                    </v-card>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>

      <v-layout row wrap>
        <v-row>
          <v-col>
            <v-card outlined>
              <v-row>
                <v-col>
                  <v-img width="250px" src="img/token1.jpg"></v-img>
                </v-col>
                <v-col>
                  <strong><span style="color:red;">АНТИ</span>потеряшка металлический жетон</strong>
                  <v-card flat>
                    <v-card-text>
                      Изготовлен из нержавеющей стали толщиной 1.5 мм. Номер и QR-код выгравированы лазером. В комплекте кольцо для крепления.
                      <br><br>
                      <big style="color:red"><strong>120 рублей</strong></big>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined>
              <v-row>
                <v-col>
                  <v-img width="250px" src="img/token2.jpg"></v-img>
                </v-col>
                <v-col>
                  <strong><span style="color:red;">АНТИ</span>потеряшка пластиковая карта</strong>
                  <v-card flat>
                    <v-card-text>
                      Пластиковая карта стандартного размера. Стойкое изображение дополнительно защищено пленкой. В комплекте цепочка для крепления.
                      <br><br>
                      <big style="color:red"><strong>50 рублей</strong></big>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <v-col>
            <v-card outlined>
              <v-row>
                <v-col>
                  <v-img width="250px" src="img/qr-vk.jpg"></v-img>
                </v-col>
                <v-col>
                  <strong>Доставка <span style="color:red;">АНТИ</span>потеряшки БЕСПЛАТНА</strong>
                  <v-card flat>
                    <v-card-text>
                      Вы можете задать вопрос используя виджет внизу и справа или перейти в <a href="https://vk.com/ekarta43" target="__blank">группу ВКонтакте</a>:
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-layout>
        <v-row justify-center>
          <v-col>
            <v-flex xs12 sm12><br><br>
              <h2>Короткое видео про <span style="color:red;">АНТИ</span>потеряшку</h2><br>
              <iframe
                max-height="100%"
                height="500"
                width="100%"
                src="https://www.youtube.com/embed/IUpoXyK6Rbo"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="pa-0"
              ></iframe>
            </v-flex>
          </v-col>
          <v-col>
            <v-flex xs12 sm12>
              <br><br>
              <h2>Примеры использования <span style="color:red;">АНТИ</span>потеряшки</h2><br>
              <v-carousel
                cycle
                max-height="480"
                :show-arrows="false"
                hide-delimiter-background
                hide-delimiters
              >
                <v-carousel-item
                  cycle
                  v-for="(item,i) in slides"
                  :key="i"
                  :src="item.src"
                  reverse-transition="fade-transition"
                  transition="fade-transition"
                >
                  <!-- <v-img :src="item.src"></v-img> -->
                </v-carousel-item>
              </v-carousel>
            </v-flex>
          </v-col>
        </v-row>
    </v-main>

  </v-container>
</template>

<script>
export default {
  name: 'Index',
  data: () => ({
    cases: [
      { 'id': '1', 'title': 'Школьники', 'text': 'Здесь "букет" применений. И ключи, и ранец, и пакет со сменной обувью' },
      { 'id': '2', 'title': 'Пожилые', 'text': 'Теряются, забывают кто они. Нужна регулярная медицинская помощь' },
      { 'id': '3', 'title': 'Животные', 'text': 'В первую очередь собаки. Сколько их теряется, погибает, усыпляется...' },
      { 'id': '4', 'title': 'Багаж', 'text': 'Потерянный багаж получает шанс найти своего настоящего хозяина' },
      { 'id': '5', 'title': 'Ключи', 'text': 'Мы иногда теряем ключи. Их кто-то находит, но не знает кому вернуть' },
      { 'id': '6', 'title': 'Прочее', 'text': 'Вероятно, найдутся и другие применения' }
    ],
    slides: [
      { src: 'img/slide1.jpg', },
      { src: 'img/slide2.jpg', },
      { src: 'img/slide3.jpg', },
      { src: 'img/slide4.jpg', },
      { src: 'img/slide5.jpg', },
      { src: 'img/slide6.jpg', },
      { src: 'img/slide7.jpg', },
      { src: 'img/slide8.jpg', },
      { src: 'img/slide9.jpg', },
      { src: 'img/slide10.jpg', },
      { src: 'img/slide11.jpg', },
      { src: 'img/slide12.jpg', },
    ],
  })
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #448AFF;
}
</style>
